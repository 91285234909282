import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import MarkdownRenderer from 'react-markdown-renderer';
import image from '../img/bild-tagesablauf.jpg';
import TextAndImagePage from '../components/TextAndImagePage';

const SchedulePage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TextAndImagePage
        title={post.frontmatter.title}
        image={image}
        backLink="/konzept"
        content={
          <>
            <ul>
              {post.frontmatter.schedule.map(({ item }) => (
                <li>{item}</li>
              ))}
            </ul>
            <MarkdownRenderer markdown={post.frontmatter.text} />
          </>
        }
      />
    </Layout>
  );
};

export default SchedulePage;

export const query = graphql`
  query SchedulePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        schedule {
          item
        }
        text
      }
    }
  }
`;
